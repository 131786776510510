<template>
  <div class="container">
    <div class="cui__utils__heading">
      <strong>Clients</strong>
    </div>
    <b-card no-body>
      <b-table
      class="card-table"
      show-empty
      hover
      :fields="fields"
      :items="clients"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      responsive
      :busy="tableBusy"
      >
        <template #cell(actions)="data">
          <b-button variant="secondary" :to="`/clients/${data.item.id}`">Edit</b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  data() {
    return {
      fields: [
        { key: 'id', sortable: true, tdClass: 'align-middle td-shrink text-nowrap' },
        { key: 'name', sortable: true, tdClass: 'align-middle' },
        { key: 'actions', label: '', sortable: false, tdClass: 'align-middle td-shrink' }
      ],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      tableBusy: false
    }
  },
  computed: {
    ...mapState('ocapi', [
      'allCustomerGroups'
    ]),
    ...mapGetters('ping', [
      'isAccountAdmin',
      'manageableAccounts',
    ]),
    clients() {
      return this.manageableAccounts?.length ? this.manageableAccounts : this.allCustomerGroups.filter(item => item.id !== 'All');
    }
  }
}
</script>
